import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  QueryList,
  ViewChildren,
} from "@angular/core";
import {
  PageSettingsModel,
  ToolbarItems,
  GridComponent,
  FilterSettingsModel,
  PdfExportProperties,
  ExcelExportProperties,
} from "@syncfusion/ej2-angular-grids";
import { DataUtil } from "@syncfusion/ej2-data";
import { ClickEventArgs } from "@syncfusion/ej2-angular-navigations";
import { DialogComponent } from "@syncfusion/ej2-angular-popups";
import { DatePipe } from "@angular/common";
import { Router } from "@angular/router";
import { CONFIG } from "../../../Core/config";
import { TabComponent } from "@syncfusion/ej2-angular-navigations";
import { CustomSyncFusionGridService } from "../../Services/customSyncfusionGrid.service";
import { UserInfoService } from "../../../Services/userInfo.service";
import { UnitConverterService } from "../../../Services/unit-converter.service";
import { DashboardService } from "../../dashboard/dashboard.service";
import { LoadingService } from "../../../Services/loading.service";
import { TranslateService } from "src/language-translation";

@Component({
  selector: "tl-manufacturer-popup",
  templateUrl: "./manufacturer-popup.component.html",
  styleUrls: ["./manufacturer-popup.component.scss"],
})
export class ManufacturerPopupComponent implements OnInit {
  @Input() ManufacturerInfo: any;
  @ViewChild("tab", { static: false }) tabObj: TabComponent;
  @ViewChild("ManufacturerDialog", { static: false })
  public ManufacturerDialog: DialogComponent;

  @ViewChildren("DynamicGrid") components: QueryList<GridComponent>;
  @Output() manufacturerDialogClose = new EventEmitter();

  public data: any;
  public manufacturerCount: number;
  public showContainerId = true;
  public measurementUnit = "Metric";
  public tempUnit = "C";

  public confirmHeader;
  public visible: Boolean = true;
  public hidden: Boolean = true;
  public toolbarOptions: ToolbarItems[] | object;
  public filterOptions: FilterSettingsModel;
  public pageSettings: PageSettingsModel;
  public formatOptions: object;
  public decimalpoint: any;
  public target = ".control-section";
  public dialogdragging: Boolean = true;
  public dialogResize: Boolean = true;
  public animationSettings: Object = { effect: "None" };
  public confirmCloseIcon: Boolean = true;
  public isModal: Boolean = true;
  public showManufacturerData: Boolean = false;
  public dateFormat: string;
  manufacturersListRes = null;
  manufacturersCount: any;

  constructor(
    public datepipe: DatePipe,
    public customSyncFusionGridService: CustomSyncFusionGridService,
    private LoadingService: LoadingService,
    private _translate: TranslateService,
    private _dashboardService: DashboardService,
    public router: Router,
    private userInfoService: UserInfoService,
    public UnitConverterService: UnitConverterService
  ) {
    this.filterOptions = {
      type: "Excel",
    };
    this.pageSettings = { pageSize: 10 };
    this.dateFormat = this.UnitConverterService.getDateFormat();
    this.formatOptions = {
      type: "dateTime",
      format: this.dateFormat + " hh:mm:ss a",
    };
    this.decimalpoint = "N2"; //{ format: 'N2' };
    this.toolbarOptions = ["ExcelExport", "PdfExport", "Search"];
    this.measurementUnit = this.userInfoService.loggedinUserData
      ? this.userInfoService.loggedinUserData.UnitConversionType
      : sessionStorage.getItem("UserInfo")
      ? JSON.parse(sessionStorage.getItem("UserInfo")).UnitConversionType
      : "";

    if (this.measurementUnit == "Imperial") {
      this.tempUnit = "F";
    }
  }

  parseInitialData() {
    this.showContainerId = this.data.fromHistorypage
      ? this.data.fromHistorypage
      : false;
    if (this.manufacturersListRes) {
      this.manufacturersListRes.map((element: any) => {
        if (element && element.ReeferAssetStatuses) {
          element.ReeferAssetStatuses.forEach((item) => {
            item.EventTimeUTC = this.UnitConverterService.convertTimeZone(
              item.EventTimeUTC
            );
            if (this.tempUnit === "F") {
              item.AmbientTemp = this.UnitConverterService.tempConverttoFixed(
                item.AmbientTemp
              );
            } else if (this.tempUnit === "C") {
              item.AmbientTemp = this.UnitConverterService.temptoFixed(
                item.AmbientTemp
              );
            }
          });
        }
      });
    }
  }
  onTabCreatedHandler(args: void) {
    setTimeout(() => {
      let tabObj = (document.querySelector(".e-tab") as any).ej2_instances[0];
      if (this.manufacturersListRes.length > 0) {
        const index = this.manufacturersListRes.findIndex((item) => {
          return item.ManufacturerName.toLowerCase() == this.ManufacturerInfo.tabName.toLowerCase();
        });
        this.tabObj.selectedItem = index;
      }
      tabObj.refreshActElePosition();
    }, 50);
  }
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  ngOnInit() {
    if (this.ManufacturerInfo) {
      this.LoadingService.show();
      this._dashboardService
        .getManufacturerDetails(
          this.ManufacturerInfo.manufacturerData.apiObject
        )
        .subscribe((response) => {
          this.manufacturersListRes =
            response && response.ManufacturersList
              ? response.ManufacturersList
              : [];
          // this.manufacturersListRes.forEach(element => {
          //     element.ManufacturerName = this.capitalizeFirstLetter(element.ManufacturerName);
          // });
          let manufacturersCount = 0;
          response.manufacturerCounts.forEach((item) => {
            manufacturersCount = manufacturersCount + item.Count;
          });
          this.manufacturersCount = response.manufacturerCounts;
          this.confirmHeader =
            "Manufacturers" + " (" + this.manufacturersListRes.length + ")";
          this.data = response;
          this.parseInitialData();
          this.showManufacturerData = true;
          this.LoadingService.hide();
        });
    }
  }

  toolbarClick(args: ClickEventArgs, val): void {
    let checkNum;
    const type = val.toLowerCase();
    checkNum = this.getGridReference(type);
    const pdfExportProperties: PdfExportProperties = {
      pageOrientation: "Landscape",
      fileName: "Manufacturer.pdf",
    };
    switch (args.item.text) {
      case "PDF Export":
        checkNum.pdfExport(pdfExportProperties);
        break;
      case "Excel Export":
        const excelExportProperties: ExcelExportProperties = {
          fileName: "Manufacturer.xlsx",
        };
        checkNum.excelExport(excelExportProperties);
        break;
      case "CSV Export":
        checkNum.csvExport();
        break;
    }
  }

  dataBound(data) {
    if (
      this.components &&
      this.components.toArray() &&
      this.components.toArray().length
    ) {
      this.components.toArray().forEach((item) => {
        this.customSyncFusionGridService.showNoRecordstoView(
          item,
          data,
          this.customSyncFusionGridService.lengthGreatreThanZero
        );
        item.autoFitColumns();
        let toolObjCritical;
        let iscriticalhide;
        toolObjCritical = item.toolbarModule.toolbar;
        iscriticalhide = item.currentViewData.length ? false : true;
        toolObjCritical.hideItem(
          toolObjCritical.items.findIndex((c) => c.text === "PDF Export"),
          iscriticalhide
        );
        toolObjCritical.hideItem(
          toolObjCritical.items.findIndex((c) => c.text === "Excel Export"),
          iscriticalhide
        );
      });
    }
  }

  public tabCreated(): void {
    if (this.manufacturersListRes.length > 0) {
      const index = this.manufacturersListRes.findIndex((item) => {
        return item.ManufacturerName.toLowerCase() == this.ManufacturerInfo.tabName.toLowerCase();
      });
      this.tabObj.selectedItem = index;
    }
  }
  getGridReference(gridId) {
    let gridRefObj = null;
    const currentGrid = gridId.toLowerCase();
    this.components.toArray().forEach((item) => {
      if (
        item.element.id &&
        item.element.id.toLowerCase().indexOf(currentGrid) !== -1
      ) {
        gridRefObj = item;
        return gridRefObj;
      }
    });
    return gridRefObj;
  }

  navigateAssetHistory(invNumData, val) {
    let invNum;
    const type = val.toLowerCase();
    const refObj = this.getGridReference(type);
    invNum = refObj.getRowInfo(invNumData.target).rowData;
    const assetdata = {
      startdate: this.datepipe.transform(
        invNum["EventTimeUTC"],
        this.dateFormat
      ),
      endDate: this.datepipe.transform(invNum["EventTimeUTC"], this.dateFormat),
    };
    const data = { type: "dashboard", data: assetdata };
    this.LoadingService.show();
    this.router.navigate(
      [
        CONFIG.ROUTE_URLS.AssetDetails,
        invNum["DeviceID"],
        invNum["ContainerID"],
      ],
      { state: { data } }
    );
  }

  manufacturerDialogClosed() {
    this.manufacturerDialogClose.emit(0);
  }
}
