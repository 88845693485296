// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const protectedResourceMap: [string, string[]][] = [
  ['https://buildtodoservice.azurewebsites.net/api/todolist',  ["https://triplinkdev.onmicrosoft.com/triplinkapidev/read"]],
  ['https://graph.microsoft.com/v1.0/me',  ["https://triplinkdev.onmicrosoft.com/triplinkapidev/read"]]
];
export const environment = {
    production: true,
    hmr: false,
    tripLinkConfig: {
        tenant: 'triplinkdev.onmicrosoft.com',
        clientId: "3318f935-b137-4e50-92a9-f8b3a7aab0c8",
        // usersApi: 'https://triplinkusermgmtapidev.azurewebsites.net/',
        usersApi: 'https://dev.api.container.lynxfleet.carrier.com/usermgmtservice',
        genSetApi:'https://triplinkgensetapidev.azurewebsites.net',
        // assetApi: 'https://triplinkreeferapidev.azurewebsites.net',
        assetApi: 'https://dev.api.container.lynxfleet.carrier.com/reeferservice',
        //filtersApi: 'https://triplinkfilterapidev.azurewebsites.net', 
        filtersApi: 'https://dev.api.container.lynxfleet.carrier.com/filterservice',       
        authority: "https://triplinkdev.b2clogin.com/tfp/triplinkdev.onmicrosoft.com/B2C_1_triplinkdevsignupin",
        forgotPasswordAuthority: "https://triplinkdev.b2clogin.com/tfp/triplinkdev.onmicrosoft.com/B2C_1_triplinkdevresetpwd",
        forgotPasswordScope: "B2C_1_triplinkdevresetpwd",
        redirectUri: 'https://dev.container.lynxfleet.carrier.com/',
        cacheLocation: 'localStorage',
        postLogoutRedirectUri: 'https://dev.container.lynxfleet.carrier.com/',
        GoogleMapapiKey: 'AIzaSyBcw5Fy57o_5V92xHiGW_eR1pCiFDKXeIA',
        validateAuthority: false,
        extraQueryParameters : "",
        storeAuthStateInCookie: false,
        navigateToLoginRequestUrl: true,
        popUp: false,
        consentScopes: ["https://triplinkdev.onmicrosoft.com/triplinkapidev/read"],
        unprotectedResources: ["https://www.microsoft.com/en-us/"],
        correlationId: '443a',   
        piiLoggingEnabled: true,
        loadFrameTimeout : 6,
        protectedResourceMap : protectedResourceMap,
        dashboardAPI: 'https://dev.api.container.lynxfleet.carrier.com/uiservice',
        versionNumber : "v 7.3.5",
        version: "0.0.0"
    },
    appInsights: {   
      instrumentationKey : '20bffbf9-b367-4f46-be4c-b5584e40f5ab'
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
